<template>
  <section>
    <div class="container container--full-width">
      <div class="background">
        <div class="container grid">
          <h1>Kwartaal&shy;overzicht <br> {{ client_name }}</h1>
          <p>Welkom op jullie persoonlijke kwartaaloverzicht van {{ dataset.year }} Q{{ dataset.quarter }}. Een korte tocht langs de belangrijkste hoogtepunten en activiteiten van {{ client_name }} op Temper. Hoe zijn jullie gemiddeld beoordeeld? Welk uurtarief hebben jullie gemiddeld betaald? Je ontdekt het in het kwartaaloverzicht van {{ dataset.year }} Q{{ dataset.quarter }}. Tot slot staan we uiteraard ook dit kwartaal weer voor jullie klaar. Mochten jullie vragen hebben over dit overzicht of een nieuwe shift willen plannen helpen we graag.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Intro',
  props: [
    'client_name',
    'dataset',
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 8.8rem 0;
  border-top-right-radius: 14.4rem;
  border-bottom-left-radius: 14.4rem;
  background-color: $cl-grey;

  @include max(m) {
    padding: 3.2rem 1.6rem;
    border-top-right-radius: 7.2rem;
    border-bottom-left-radius: 7.2rem;
  }
}

h1 {
  margin-bottom: 5.6rem;
  font-size: 9.6rem;
  text-align: center;
  color: $cl-primary;

  @include max(s) {
    margin-bottom: 3.2rem;
    font-size: 3.2rem;
  }

  @include min(s) {
    padding: 0 3.2rem;
  }

  @include range(s, m) {
    margin-bottom: 3.2rem;
    font-size: 6.4rem;
  }
}

p {
  @extend .col-m8;
  @extend .push-m2;
  font-size: 2rem;
  line-height: 1.7;
  text-align: center;

  @include max(m) {
    font-size: 1.8rem;
  }
}
</style>
