<template>
  <section
    :class="[
      'section',
      variant
    ]"
  >
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="grid">
        <div class="grid__row">
          <div class="block block--image block--image-01"></div>
          <div class="block block--coverage block--green block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ Math.round(coverage * 100) }}%</div>
              <div class="block__description">Dekkings&shy;graad</div>
            </div>
          </div>
        </div>
        <div class="grid__row">
          <div class="block block--placed-shifts block--light-purple block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ shifts_placed }}</div>
              <div class="block__description">Geplaatste shifts</div>
            </div>
          </div>
          <div class="block block--worked-shifts block--purple block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ shifts_worked }}</div>
              <div class="block__description">Gewerkte shifts</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'IntroSection',
  props: [
    'title',
    'variant',
    'coverage',
    'shifts_placed',
    'shifts_worked'
  ],
  data() {
    return {

    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.section {
  position: relative;
  padding: 8rem 0;
  overflow: hidden;

  &::before {
    content: '';
    z-index: $zIndex--bottom;
    position: absolute;
    top: 14.8rem;
    width: 100%;
    height: calc(100% - 11.6rem);
    background-color: $cl-secondary-darkest;

    @include max(m) {
      top: 13.2rem;
    }
  }

  @include min(m) {
    padding: 8rem 0;
  }

  @include max(m) {
    padding-bottom: 1.6rem;
  }
}

h2 {
  height: 2rem;
  margin-bottom: 4.8rem;
  font-size: 1.8rem;
  line-height: 1.1;
  font-family: $fn-secondary;

  @include max(m) {
    margin-bottom: 3.2rem;
  }
}

.grid__row {
  @extend .grid;
  grid-gap: 0;
}

.block {
  @extend .col-m4;
  position: relative;
  background-color: $cl-primary;
  padding-top: 100%;

  > * {
    position: absolute;
  }
}

.block__content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 3.2rem;

  @include max(m) {
    padding: 1.6rem;
  }
}

.block__value {
  font-family: $fn-primary;
  font-size: 8.8rem;
  line-height: 1.1;

  @include max(m) {
    font-size: 3.2rem;
  }
}

.block__description {
  font-family: $fn-primary;
  font-size: 2rem;
  line-height: 1.1;

  @include max(m) {
    font-size: 1.6rem;
  }
}

.block--mobile-small {
  @extend .col6;
  @extend .col-m4;
}

.block--image {
  background-size: cover;
  background-position: 50%;

  &::before {
    content: '';
    z-index: $zIndex--bottom;
    position: absolute;
    top: 0;
    left: 100%;
    width: 75vw;
    height: 100%;
    background-color: $cl-white;
  }
}

.block--image-01 {
  background-image: url('../assets/img/img01.jpg');

  @include max(m) {
    background-image: url('../assets/img/img01-mobile.jpg');
  }
}

.block--green {
  background-color: $cl-secondary-darker;
}

.block--light-purple {
  background-color: $cl-primary-lightest;
  color: $cl-primary;
}

.block--purple {
  background-color: $cl-primary;
  color: $cl-primary-lightest;
}

.block--green {
  background-color: $cl-secondary-darker;
  color: $cl-secondary;
}

.block--coverage {
  @extend .push6;
  @extend .push-m0;

  @include max(m) {
    margin-top: -100%;
  }
}

.block--placed-shifts {
  @extend .push-m4;
}

.block--worked-shifts {
  @extend .push-m8;
}
</style>
