<template>
  <header>
    <div class="container container--full-width container--flex">
      <img class="logo" alt="Temper logo" src="../assets/img/temper-logo.svg">
      <div class="header__navigation">
        <v-select
          v-model="selected"
          :options="storeOptions"
          :searchable="false"
          :clearable="false"
          @input="changeRoute"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: [
    'client_name',
    'assoc_clients',
    'user_id',
    'unique_id',
    'dataset',
  ],
  data() {
    const storeOptions = this.assoc_clients.map(client => {
      return {
        label: client.client_name,
        unique_id: client.unique_id
      };
    });

    return {
      selected: this.client_name,
      storeOptions
    };
  },
  methods: {
    changeRoute(option) {
      if (option.unique_id !== this.unique_id) {
        this.$parent.fetchData(this.user_id, option.unique_id, this.dataset);
        window.history.pushState({}, null, `/${this.dataset.year}/${this.dataset.quarter}/${this.user_id}/${option.unique_id}`);

        console.log(`/${this.dataset.year}/${this.dataset.quarter}/${this.user_id}/${option.unique_id}`);
      }
    }
  },
  watch: {
    'client_name': function(newVal) {
      this.selected = newVal;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.container {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  align-items: center;
  justify-content: space-between;

  @include max(s) {
    flex-direction: column;
    padding-bottom: 2.4rem;
  }
}

.logo {
  margin-right: 1.6rem;

  @include max(s) {
    margin-bottom: 2.4rem;
  }
}

.header__navigation {
  width: 100%;
  max-width: 40rem;
}

::v-deep {
  .v-select {
    font-size: 1.4rem;
    font-weight: $fn-weight--medium;
  }

  .vs1__combobox {
    overflow: hidden;
  }

  .vs__dropdown-toggle {
    padding: 0;
    padding-right: 1.6rem;
    border: 0;
    background-color: $cl-grey;
  }

  .vs__selected-options {
    padding: 0;
    overflow: hidden;
  }

  .vs__selected-options .vs__selected {
    margin: 0;
    padding: 1.6rem 2.4rem 1.6rem 1.6rem;
    line-height: 1.1;

    .vs--open & {
      position: relative;
    }
  }

  .vs__search {
    position: absolute;
  }

  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .vs__open-indicator {
    display: none;
  }

  .vs__actions {
    width: 3.2rem;
    height: 3.2rem;
    margin: auto;
    padding: 0;
    flex-shrink: 0;
    background: url('../assets/img/arrow-down.svg') 50% no-repeat;
    transition: transform 200ms;

    .vs--open & {
      transform: rotate(180deg);
    }
  }

  .vs__dropdown-menu {
    padding: 0;
    box-shadow: none;
  }

  .vs__dropdown-option {
    padding: 1rem 1.6rem;
  }

  .vs__dropdown-option--highlight {
    background: $cl-primary;
  }
}
</style>
