<template>
  <div class="scroll-indicator-container">
    <div class="container container--full-width">
      <div class="scroll-indicator">
        <div class="scroll-indicator__inside">Scroll naar beneden</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollDownIndicator',
  mounted() {
    setTimeout(() => {
      if (document.readyState == 'complete') {
        resizeContainer();
      }

      window.onresize = resizeContainer;
    }, 500);

    setInterval(resizeContainer, 2000);
  },
}

const resizeContainer = () => {
  const firstImage = document.querySelector('.block--image-01');
  const container = document.querySelector('.scroll-indicator-container');
  const rect = firstImage.getBoundingClientRect();

  container.style.height = `${Math.round(rect.bottom + window.scrollY)}px`;
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.scroll-indicator-container {
  z-index: $zIndex--second;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @include max(s) {
    display: none;
  }
}

.container {
  height: 100%;
  animation: fadeIn 500ms 500ms ease-out both;
}

.scroll-indicator {
  position: sticky;
  top: calc(50vh - 9rem);
  left: 100%;
  width: 4.8rem;
  height: 23.7rem;
  color: $cl-primary;
  transform: translateX(100%);

  @include max(m) {
    height: 20.6rem;
  }
}

.scroll-indicator__inside {
  top: 0;
  display: inline-flex;
  align-items: center;
  padding-left: $sz-container-padding * .5;
  white-space: nowrap;
  font-family: $fn-primary;
  font-weight: $fn-weight--medium;
  font-size: 1.6rem;
  color: $cl-primary;
  transform-origin: 0 0;
  transform: rotate(-90deg) translate(-100%, calc(#{$sz-container-padding * .5} - 50%));

  &::before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
    background: url('../assets/img/arrow-left-purple.svg') no-repeat 50%;
  }

  @include max(m) {
    padding-left: $sz-container-padding--tablet * .5;
    font-size: 1.4rem;
    transform: rotate(-90deg) translate(-100%, calc(#{$sz-container-padding--tablet * .5} - 50%));
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
