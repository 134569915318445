<template>
  <footer>
    <div class="container container--flex">
      <div class="content">
        <p class="footer__intro">Bedankt</p>
        <h2 class="footer__title">Tot volgend<br>kwartaal!</h2>
        <p class="footer__contact-name">{{ account_manager }}</p>
        <a class="footer__link footer__contact-phone" :href="'tel:' + phone">{{ phone }}</a>
        <a class="footer__cta" href="https://temper.works/dashboard/planning" v-on:click="(e) => this.$parent.trackEvent(e, 'plan-shift-cta-link')">Plan je volgende shift</a>
      </div>
    </div>
    <div class="footer__bottom">
      <a target="_blank" class="footer__link footer__faq-link" href="https://help.temper.works/nl/articles/4851064-faq-jaaroverzicht-temper-2020">Ik heb een vraag</a>
      <img class="logo" alt="Temper logo" src="../assets/img/temper-logo.svg">
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: [
    'account_manager',
    'phone'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

footer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $cl-primary-lightest;

  @include max(s) {
    min-height: 100vh;
  }
}

.content {
  margin: auto;
  padding: 9.6rem 0;
  text-align: center;

  @include min(s) {
    padding-bottom: 20vh;
  }
}

.container {
  height: 100%;
}

.footer__intro {
  margin-bottom: 1.6rem;
  font-family: $fn-primary;
  font-size: 2.3rem;
  line-height: 1.1;
  font-weight: $fn-weight--medium;

  @include max(m) {
    font-size: 1.6rem;
  }
}

.footer__title {
  margin-bottom: 7.2rem;
  font-size: 8.8rem;
  font-weight: $fn-weight--medium;
  line-height: 1;
  color: $cl-primary;

  @include max(m) {
    margin-bottom: 4.8rem;
    font-size: 4.8rem;
  }
}

.footer__contact-name {
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: $fn-weight--bold;

  @include max(m) {
    font-size: 1.4rem;
  }
}

.footer__contact-phone,
.footer__faq-link {
  display: block;
  font-size: 1.6rem;
  line-height: 1.7;

  @include max(m) {
    font-size: 1.4rem;
  }
}

.footer__contact-phone {
  color: $cl-black;
}

.footer__faq-link {
  color: $cl-primary;
}

.footer__cta {
  display: inline-block;
  margin-top: 1.6rem;
  padding: 1.6rem 2.4rem;
  border-radius: .4rem;
  color: $cl-white;
  text-decoration: none;
  font-weight: $fn-weight--bold;
  background-color: $cl-primary;
  transition: background-color 200ms;

  &:hover {
    background-color: $cl-primary-hover;
  }

  @include max(m) {
    font-size: 1.4rem;
    padding: 1.4rem 2rem;
  }
}

.footer__link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  padding-bottom: 4.8rem;

  @include max(s) {
    padding-bottom: 3.2rem;
  }
}

.logo {
  margin-top: 1.6rem;
}
</style>
