<template>
  <section
    :class="[
      'section',
      variant
    ]"
  >
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="grid">
        <div class="grid__row">
          <div class="block block--purple block--small block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ parseFloat(parseFloat(most_active_ff).toFixed(1)) }} <span class="smaller">uur</span></div>
              <div class="block__description">Jullie meest actieve FreeFlexer werkte <span class="highlight">{{ parseFloat(parseFloat(most_active_ff).toFixed(1)) }} uur</span></div>
            </div>
          </div>
          <div class="block block--light-purple block--small block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ ff_applied }} <span class="smaller">FreeFlexers</span></div>
              <div class="block__description"><span class="highlight">{{ ff_applied }} FreeFlexers</span> meldden zich aan voor een shift</div>
            </div>
          </div>
          <div class="block block--green block--small block--mobile-small block--hide-mobile">
            <div class="block__content">
              <div class="block__value">{{ ff_worked }} <span class="smaller">FreeFlexers</span></div>
              <div class="block__description"><span class="highlight">{{ ff_worked }} FreeFlexers</span> hebben bij jullie gewerkt</div>
            </div>
          </div>
        </div>
        <div class="grid__row">

          <div class="row-background"></div>

          <div class="block block--image block--small block--image-02"></div>

          <div class="block block--green block--small block--mobile-small block--hide-desktop">
            <div class="block__content">
              <div class="block__value">{{ ff_worked }} <span class="smaller">FreeFlexers</span></div>
              <div class="block__description"><span class="highlight">{{ ff_worked }} FreeFlexers</span> hebben bij jullie gewerkt</div>
            </div>
          </div>

          <div class="block block--purple block--small block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ feedback_to }}/{{ ff_worked }} <span class="smaller">FreeFlexers</span></div>
              <div class="block__description">Jullie voorzagen {{ feedback_to }} van de {{ ff_worked }} FreeFlexers van geschreven feedback</div>
            </div>
          </div>
          <div class="block block--dark-green block--small block--mobile-small">
            <div class="block__content">
              <div class="block__value">{{ first_shift }} <span class="smaller">Eerste shift</span></div>
              <div class="block__description">Jullie eerste shift ooit op Temper was op {{ first_shift }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'IntroSection',
  props: [
    'title',
    'variant',
    'most_active_ff',
    'ff_applied',
    'ff_worked',
    'feedback_to',
    'first_shift'
  ],
  data() {
    return {

    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.section {
  overflow: hidden;
  position: relative;
  padding: 12rem 0 0;
  background-color: $cl-primary-darker;

  @include max(m) {
    padding-top: 9.6rem;
  }
}

h2 {
  height: 2rem;
  margin-bottom: 4.8rem;
  font-size: 1.8rem;
  line-height: 1.1;
  font-family: $fn-secondary;
  color: $cl-white;

  @include max(m) {
    margin-bottom: 3.2rem;
  }
}

.grid__row {
  @extend .grid;
  grid-gap: 0;

  &:last-child {
    position: relative;

    @include max(m) {
      grid-template-rows: 1.7fr 1fr;
    }
  }
}

.row-background {
  content: '';
  z-index: $zIndex--bottom;
  position: absolute;
  top: 0;
  left: -50vw;
  width: 150vw;
  height: 100%;
  background-color: $cl-primary-lightest;

  @include max(m) {
    top: 63%;
    height: 37%;
  }
}

.block {
  @extend .col-m4;
  position: relative;
  background-color: $cl-primary;

  @include min(m) {
    height: 36.7rem;
  }
}

.block__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 3.2rem;

  @include max(m) {
    padding: 1.6rem;
  }
}

.block__value {
  font-family: $fn-primary;
  font-size: 4rem;
  line-height: 1.1;

  .smaller {
    display: block;
    font-size: 2rem;

    @include max(m) {
      font-size: 1.6rem;
    }
  }

  @include max(m) {
    font-size: 2.4rem;
    margin-bottom: 6.4rem;
  }
}

.block__description {
  font-family: $fn-secondary;
  font-size: 1.6rem;
  line-height: 1.7;

  .highlight {
    font-weight: $fn-weight--bold;
  }

  @include max(m) {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.block--small {
  @extend .col-m3;
}

.block--mobile-small {
  @extend .col6;
  @extend .col-m4;
}

.block--image {
  position: relative;
  @extend .push-m3;
  background-size: cover;
  background-position: 50%;
}

.block--grid-spacer {
  @extend .col6;
  @extend .push6;
  background: transparent;
  padding-top: 200%;
}

.block--hide-mobile {
  @include max(m) {
    display: none;
  }
}

.block--hide-desktop {
  @include min(m) {
    display: none;
  }
}

.block--image-02 {
  background-image: url('../assets/img/img02.jpg');

  @include max(m) {
    background-image: url('../assets/img/img02-mobile.jpg');
  }
}

.block--green {
  background-color: $cl-secondary-darker;

  @include max(m) {
    @include start(7);
    grid-row-start: 2;
    transform: translateY(-100%);
  }
}

.block--light-purple {
  background-color: $cl-primary-lightest;
  color: $cl-primary;

  .block__description {
    color: $cl-black;
  }
}

.block--purple {
  background-color: $cl-primary;
  color: $cl-primary-lightest;

  .block__description {
    color: $cl-white;
  }
}

.block--green {
  background-color: $cl-secondary-darker;
  color: $cl-secondary;

  .block__description {
    color: $cl-white;
  }

  @include max(m) {
    width: 100%;
    position: absolute;
  }
}

.block--dark-green {
  background-color: $cl-secondary-darkest;
  color: $cl-primary-lighter;

  .block__description {
    color: $cl-white;
  }
}
</style>
