<template>
  <div class="scroll-to-top-indicator-container">
    <div class="container container--full-width">
      <div class="scroll-indicator" v-bind:class="{ 'is-visible': isVisible }">
        <button class="scroll-indicator__inside" v-on:click="scrollToTop">Terug naar boven</button>
      </div>
    </div>
  </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'ScrollToTopIndicator',
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
    smoothscroll.polyfill();

    window.onscroll = this.scrollHandler;
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollHandler() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.isVisible = (scrollTop > window.innerHeight * .5);
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.scroll-to-top-indicator-container {
  z-index: $zIndex--second;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.container {
  position: relative;
  height: 100%;
}

.scroll-indicator {
  position: absolute;
  bottom: $sz-container-padding;
  right: $sz-container-padding;
  transform: translateY(50%);
  opacity: 0;
  transition: {
    property: transform, opacity;
    duration: 200ms;
  };

  &.is-visible {
    transform: translateY(0);
    opacity: 1;
  }

  @include max(s) {
    bottom: $sz-container-padding--mobile;
    right: $sz-container-padding--mobile;
  }

  @include range(s, m) {
    bottom: $sz-container-padding--tablet;
    right: $sz-container-padding--tablet;
  }
}

.scroll-indicator__inside {
  appearance: none;
  width: 5.6rem;
  height: 5.6rem;
  padding: 0;
  border: 0;
  text-indent: -9999px;
  color: transparent;
  background: url('../assets/img/arrow-up.svg') no-repeat 50%;
  background-size: cover;
  pointer-events: auto;
  cursor: pointer;
  outline: none;

  @include max(m) {
    width: 4.8rem;
    height: 4.8rem;
  }
}
</style>
