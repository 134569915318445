<template>
  <section
    :class="[
      'section',
      variant
    ]"
  >
    <div class="container">
      <h2 v-if="title">
        {{ title }}
      </h2>
      <div class="grid">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section',
  props: [
    'title',
    'variant',
  ],
  data() {
    return {

    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/shared.scss';

.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;

  &--mobile-small {
    h2 {
      @include min(m) {
        position: absolute;
      }

      @include max(m) {
        margin-bottom: 10.2rem;
      }
    }

    @include max(m) {
      padding: 6.4rem 0;

      .grid {
        height: 100%;
        min-height: 26rem;
      }

      .section-extra-large-title {
        display: flex;
        z-index: $zIndex--top;
        height: 26rem;
        margin-bottom: 0;
        font-size: 6.4rem;
        justify-content: center;

        .smaller {
          font-size: 1.8rem;
        }
      }
    }

    @include max(s) {
      padding: 3.2rem 0;
      min-height: 65vh;
    }
  }

  &--dark-green {
    background-color: $cl-secondary-darkest;

    + .section--dark-green {
      @include max(m) {
        padding-top: 4.8rem;
        padding-bottom: 3.2rem;
      }
    }

    h2 {
      color: $cl-white;
    }
  }

  &--light-green {
    background-color: $cl-secondary;

    h2 {
      color: $cl-secondary-darkest;
    }
  }

  &--purple {
    background-color: $cl-primary-darker;

    h2 {
      color: $cl-white;
    }
  }

  .container {
    overflow: hidden;
  }

  .grid {
    position: relative;
  }
}

::v-deep {
  .section__left {

    &--subgrid {
      @extend .col-m9;
      display: flex;
      flex-direction: column;
    }

    &:not(.section__left--subgrid) {
      @extend .col-m7;

      @include max(m) {
        margin-bottom: 6.4rem;
      }
    }
  }

  .section__right {

    &--subgrid {
      @extend .push6;
      @extend .col6;
      @extend .col-m3;
      @extend .push-m9;

      @include max(m) {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
      }
    }

    &:not(.section__right--subgrid) {
      @extend .col-m5;
      @extend .push-m7;

      @include min(l) {
        padding-left: 4.8rem;
      }
    }
  }

  .section-large-title {
    margin-bottom: 4.8rem;
    font-size: 5.6rem;
    line-height: 1;
    color: $cl-secondary;

    .highlight {
      color: $cl-white;
    }

    @include max(m) {
      margin-bottom: 4rem;
      font-size: 3rem;
    }
  }

  .section-extra-large-title {
    @extend .col-s9;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    font-size: 20.8rem;
    line-height: 1.1;
    color: $cl-secondary;

    .smaller {
      font-size: 2.3rem;
      color: $cl-white;

      @include min(m) {
        br {
          display: none;
        }
      }
    }

    .section--light-green & {
      color: $cl-secondary-darkest;

      .smaller {
        color: $cl-secondary-darkest;
      }
    }

    .section--purple & {
      color: $cl-primary-lighter;
    }

    @include max(m) {
      margin-bottom: 4rem;
      font-size: 3rem;
    }
  }

  .section-description {
    color: $cl-secondary;
    font-size: 1.8rem;
    line-height: 1.4;

    .highlight {
      color: $cl-white;
    }

    &--small {
      margin-top: 4.8rem;
      font-size: 1.6rem;
      color: $cl-white;

      .highlight {
        font-weight: $fn-weight--bold;
      }

      @include min(s) {
        max-width: 25.6rem;
      }
    }

    .section--light-green & {
      color: $cl-secondary-darkest;

      .highlight {
        color: $cl-secondary-darkest;
      }
    }
  }

  .side-image {
    @extend .col-s3;
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-top-right-radius: 14.4rem;
    border-bottom-left-radius: 14.4rem;

    @include max(m) {
      max-height: 40rem;
      margin: 0 0 0 auto;
      border-top-right-radius: 9.6rem;
      border-bottom-left-radius: 9.6rem;
    }

    @include max(s) {
      max-height: 26rem;
      margin: 0 0 auto auto;
      border-top-right-radius: 7.2rem;
      border-bottom-left-radius: 7.2rem;
    }
  }

  h2 {
    margin-bottom: 4.8rem;
    font-size: 1.8rem;
    line-height: 1.1;
    font-family: $fn-secondary;

    @include max(m) {
      margin-bottom: 3.2rem;
    }
  }

  .top-months__entry {
    padding-bottom: 2.4rem;
    border-bottom: .1rem solid $cl-secondary-darker;
    color: $cl-white;

    + .top-months__entry {
      margin-top: 1.6rem;
    }

    &:first-child {
      padding-right: 4.8rem;
      color: $cl-secondary;
      background: {
        image: url('../assets/img/medal.svg');
        repeat: no-repeat;
        position: 100% 0;
        size: 4.8rem;
      };

      @include max(m) {
        padding-right: 3.2rem;
        background: {
          image: url('../assets/img/medal-mobile.svg');
          repeat: no-repeat;
          position: 100% 0;
          size: 3.2rem;
        };
      }
    }
  }

  .top-months__title {
    margin-bottom: .8rem;
    font-family: $fn-secondary;
    font-size: 1.8rem;
    line-height: 1.1;
    font-weight: $fn-weight--bold;
    text-transform: capitalize;
  }

  .top-months__description {
    display: flex;
    font-size: 1.4rem;
    font-weight: $fn-weight--bold;

    @include max(m) {
      font-size: 1.2rem;
    }
  }

  .top-months__shifts {
    margin-right: 1.6rem;

    @include max(m) {
      margin-right: .8rem;
    }
  }

  .section-cta {
    margin-top: 4rem;

    @include max(s) {
      margin-top: 2.4rem;
    }
  }

  .cta-button {
    display: inline-block;
    margin-top: 1.6rem;
    padding: 1.6rem 2.4rem;
    border-radius: .4rem;
    color: $cl-secondary-darkest;
    text-decoration: none;
    font-weight: $fn-weight--bold;
    background-color: $cl-secondary;
    transition: background-color 200ms;
    cursor: pointer;

    &:hover {
      background-color: mix($cl-secondary-darkest, $cl-secondary, 20%);
    }

    @include max(m) {
      font-size: 1.4rem;
      padding: 1.4rem 2rem;
    }
  }
}
</style>
