<template v-if="data">
  <div id="app">
    <div class="loading-wrapper" v-if="!response">
      <svg class="landing-error-icon" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#6b1edd" fill-opacity="1"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
      <svg width="88" height="22" viewBox="0 0 88 22">
        <path d="M17.3219 3.71867C13.5154 3.71867 10.502 6.68201 10.502 10.4926C10.5069 14.5535 13.3724 17.5242 17.8851 17.5242C21.3061 17.5242 22.9743 16.1492 22.9743 16.1492L22.1894 13.1705C22.1894 13.1705 20.3519 14.5933 18.0595 14.5933C14.3195 14.5933 13.8685 11.6013 13.8685 11.6013H23.5419C24.2415 7.33876 21.9419 3.71867 17.3219 3.71867ZM14.0065 8.90213C14.4355 7.70162 15.6235 6.68201 17.3945 6.68201C19.0082 6.68201 20.0466 7.68677 20.3728 8.90213H14.0065Z" fill="#691EDD"/>
        <path d="M71.2799 3.71867C67.4734 3.71867 64.46 6.68201 64.46 10.4926C64.46 14.5535 67.3304 17.5242 71.8426 17.5242C75.263 17.5242 76.9312 16.1492 76.9312 16.1492L76.1463 13.1721C76.1463 13.1721 74.3088 14.5954 72.0175 14.5954C68.2775 14.5954 67.8265 11.6029 67.8265 11.6029H77.5004C78.2006 7.33876 75.8999 3.71867 71.2799 3.71867ZM67.964 8.90213C68.3941 7.70162 69.581 6.68201 71.352 6.68201C72.9657 6.68201 74.0041 7.68677 74.3302 8.90213H67.964Z" fill="#691EDD"/>
        <path d="M8.78788 14.108C8.78788 14.108 8.05748 14.5705 7.12248 14.5705C5.93229 14.5705 5.65619 13.6825 5.65619 12.0889V6.94629H8.78788V3.99886H5.65619V0L2.37215 1.98034V3.9994H0V6.94629H2.37215V12.5165C2.37215 16.927 4.95714 17.5397 6.73034 17.5397C8.47878 17.5397 9.52048 16.9185 9.52048 16.9185L8.78788 14.108Z" fill="#691EDD"/>
        <path d="M44.8551 4.62379C42.9763 3.4323 40.5557 3.57395 38.7303 4.17659C38.0178 4.41529 37.3376 4.7359 36.7046 5.13148C34.7642 3.41268 31.9675 3.5018 29.9193 4.17659C27.3893 5.01265 25.9043 6.75744 25.9043 6.75744V17.2644H29.1977V7.56273C29.1977 7.56273 30.2812 6.64869 32.3679 6.64869C34.3803 6.64869 34.7147 7.70968 34.7147 9.5001V17.2644H38.0081V7.56273C38.0081 7.56273 39.0933 6.64869 41.18 6.64869C43.1919 6.64869 43.5257 7.70968 43.5257 9.5001V17.2644H46.8191V8.30542C46.8191 6.84391 46.1206 5.42855 44.8551 4.62379Z" fill="#691EDD"/>
        <path d="M49.25 6.72663C50.9209 5.12984 52.6935 3.72244 55.7658 3.72244C59.2858 3.72244 62.4467 6.34573 62.4467 10.5785C62.4467 14.0565 59.9937 17.5344 55.3737 17.5344V14.6411C57.4252 14.6411 59.1252 13.2024 59.1252 10.7483C59.1252 8.38548 57.5896 6.6635 55.3737 6.6635C54.3567 6.67345 53.3683 6.98866 52.5456 7.56534V22.0001H49.25V6.72663Z" fill="#691EDD"/>
        <path d="M87.9998 6.66297H86.159C85.1417 6.6727 84.1528 6.98792 83.3298 7.56481V17.2553H80.0342V6.72663C81.7056 5.12984 83.4783 3.72244 86.5506 3.72244C87.0379 3.72252 87.5238 3.77321 87.9998 3.87364V6.66297Z" fill="#691EDD"/>
      </svg>
    </div>

    <div class="error-wrapper" v-if="response && response.status === -1">
      <img class="landing-error-icon" src="./assets/img/error.svg" alt="Error"> <h1>Error loading data!</h1>
    </div>

    <ScrollToTopIndicator v-if="response && response.status === 1" />
    <ScrollDownIndicator v-if="response && response.status === 1" />

    <div class="page-wrapper" v-if="response && response.status === 2">
      <Header
        :client_name="data.client_name"
        :assoc_clients="data.assoc_clients"
        :dataset="dataset"
        :unique_id="unique_id"
        :user_id="user_id"
      />
      <IntroNotFound
        :client_name="data.client_name"
        :dataset="dataset"
        :datasetRequested="datasetRequested"
        :user_id="user_id"
        :unique_id="unique_id"
      />
    </div>

    <div class="page-wrapper" v-if="response && response.status === 1">
      <Header
        :client_name="data.client_name"
        :assoc_clients="data.assoc_clients"
        :dataset="dataset"
        :unique_id="unique_id"
        :user_id="user_id"
      />
      <Intro
        :client_name="data.client_name"
        :dataset="dataset"
      />
      <IntroSection
        title="De hoogtepunten"
        :coverage="data.total_coverage"
        :shifts_placed="data.total_published_shifts"
        :shifts_worked="data.total_worked_shifts"
      />
      <Section
        variant="section--dark-green"
      >
        <div class="section__left">
          <h2>Activiteit</h2>
          <h3 class="section-large-title">De meest actieve maand was <span class="highlight">{{ data.most_active_month.name }}</span></h3>
          <p class="section-description">Jullie hebben <span class="highlight">{{ data.months_active.length }} van de 3 maanden</span> shifts gehad.</p>
        </div>
        <div class="section__right" v-if="data.top_months.length > 1">
          <h2>Jouw kwartaal</h2>
          <div class="top-months">
            <div v-for="(month, monthIndex) in data.top_months" class="top-months__entry" :key="monthIndex">
              <h3 class="top-months__title">{{ monthIndex + 1}}. {{ month.name }}</h3>
              <div class="top-months__description">
                <div class="top-months__shifts">Geplaatste shifts: {{ month.published }}</div>
                <div class="top-months__coverage">Dekkingsgraad: {{ Math.round(month.coverage * 100) }}%</div>
              </div>
              <div class="top-months__medal"></div>
            </div>
          </div>
        </div>
      </Section>
      <Section
        id="prestaties"
        name= "prestaties"
        :title="`Jullie prestaties (${ currentSlide }/5)`"
        variant="section--dark-green"
      >
        <swiper class="swiper" :options="swiperOptions" ref="slidesSwiper" @slideChange="changeSlideIndex">
          <!-- Other Swiper styling options can be found in the ./scss/_swiper.scss -->
          <swiper-slide>
            <h3 class="section-large-title">Jullie plaatsten shifts <span class="highlight">{{ parseFloat(parseFloat(data.publishing_window).toFixed(1)) }} dagen</span> van tevoren. </h3>
            <p class="section-description">De best presterende klanten in jullie sector plaatsten hun shifts minimaal <span class="highlight">{{ parseFloat(parseFloat(data.top_quant_publishing_window).toFixed(1)) }} dagen</span> van tevoren.</p>
            <div class="section-cta">
              <a
                class="cta-button"
                href="http://help.temper.works/nl/articles/5621410-plaats-een-shift-zo-ver-mogelijk-van-tevoren"
                target="_blank"
                v-on:click="(e) => trackEvent(e, 'learn-more-link')"
              >
                  Leer meer
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <h3 class="section-large-title">Jullie accepteerden FreeFlexers <span class="highlight">{{ parseFloat(parseFloat(data.acceptance_notice).toFixed(1)) }} dagen</span> van tevoren. </h3>
            <p class="section-description">De best presterende klanten in jullie sector accepteerden FreeFlexers ten minste <span class="highlight">{{ parseFloat(parseFloat(data.top_quant_acceptance_notice).toFixed(1)) }} dagen</span> van tevoren.</p>
            <div class="section-cta">
              <a
                class="cta-button"
                href="http://help.temper.works/nl/articles/5621416-accepteer-freeflexers-zo-vroeg-mogelijk-van-tevoren"
                target="_blank"
                v-on:click="(e) => trackEvent(e, 'learn-more-link')"
              >
                  Leer meer
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <h3 class="section-large-title">Jullie reageerden in <span class="highlight">{{ parseFloat(parseFloat(data.reaction_time_applications).toFixed(1)) }} uur</span>.</h3>
            <p class="section-description">De best presterende klanten in jullie sector reageerden binnen <span class="highlight">{{ parseFloat(parseFloat(data.top_quant_reaction_time_applications).toFixed(1)) }} uur</span> op aanmeldingen.</p>
            <div class="section-cta">
              <a
                class="cta-button"
                href="http://help.temper.works/nl/articles/5621423-handel-gelijk-als-je-aanmeldingen-krijgt"
                target="_blank"
                v-on:click="(e) => trackEvent(e, 'learn-more-link')"
              >
                  Leer meer
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <h3 class="section-large-title">Jullie accepteerden checkouts van FreeFlexers in <span class="highlight">{{ parseFloat(parseFloat(data.reaction_time_checkouts).toFixed(1)) }} uur</span>.</h3>
            <p class="section-description">De best presterende klanten in jullie sector accepteerden checkouts van FreeFlexers binnen <span class="highlight">{{ parseFloat(parseFloat(data.top_quant_reaction_time_checkouts).toFixed(1)) }} uur</span>.</p>
            <div class="section-cta">
              <a
                class="cta-button"
                href="http://help.temper.works/nl/articles/5621433-accepteer-de-checkout-zo-snel-mogelijk"
                target="_blank"
                v-on:click="(e) => trackEvent(e, 'learn-more-link')"
              >
                  Leer meer
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <h3 class="section-large-title">Jullie vulden <span class="highlight">{{ parseFloat(parseFloat(data.flexpool_use * 100).toFixed(1)) }}%</span> van jullie shifts met de flexpool.</h3>
            <p class="section-description">De best presterende klanten in jullie sector vulden ten minste <span class="highlight">{{ parseFloat(parseFloat(data.top_quant_flexpool_use * 100).toFixed(1)) }}%</span> van hun shifts met hun flexpool.</p>
            <div class="section-cta">
              <a
                class="cta-button"
                href="http://help.temper.works/nl/articles/5621436-krijg-je-shifts-sneller-gevuld-met-behulp-van-je-flexpools"
                target="_blank"
                v-on:click="(e) => trackEvent(e, 'learn-more-link')"
              >
                  Leer meer
              </a>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-navigation-custom">
          <div class="swiper-button-prev-custom"></div>
          <div class="swiper-button-next-custom"></div>
        </div>
        <div class="swiper-pagination-custom"></div>
      </Section>
      <Section
        variant="section--light-green section--mobile-small"
      >
        <div class="section__left section__left--subgrid">
          <h3 class="section-extra-large-title">{{ parseFloat(parseFloat(data.rating_client).toFixed(2)) }} <span class="smaller">Gemiddelde <br> beoordeling</span></h3>
          <p class="section-description section-description--small">De Freeflexers gaven jullie een gemiddelde beoordeling van <span class="highlight">{{ parseFloat(parseFloat(data.rating_client).toFixed(2)) }}</span>. De gemiddelde beoordeling op het platform was <span class="highlight">{{ parseFloat(parseFloat(data.rating_overall).toFixed(2)) }}</span>.</p>
        </div>
        <div class="section__right section__right--subgrid">
          <img class="side-image" src="./assets/img/img-side-01.jpg" alt="Smiling waitress holding a plate.">
        </div>
      </Section>
      <Section
        variant="section--dark-green section--mobile-small"
      >
        <div class="section__left section__left--subgrid">
          <h3 class="section-extra-large-title">€{{ parseFloat(parseFloat(data.avg_price).toFixed(2)) }} <span class="smaller">Gemiddelde <br> beloning</span></h3>
          <p class="section-description section-description--small">Jullie betaalden gemiddeld <span class="highlight">€{{ parseFloat(parseFloat(data.avg_price).toFixed(2)) }} per uur</span>.</p>
        </div>
        <div class="section__right section__right--subgrid">
          <img class="side-image" src="./assets/img/img-side-02.jpg" alt="Young man filling shop stock.">
        </div>
      </Section>
      <Section
        variant="section--purple section--mobile-small"
        :title="parseFloat(data.no_response) < .25 ? 'Wauw!' : 'Oeps...'"
      >
        <div class="section__left section__left--subgrid">
          <h3 class="section-extra-large-title">{{ Math.round(data.no_response * 100) }}% <span class="smaller">Aanmeldingen <br> zonder reactie</span></h3>
          <p class="section-description section-description--small"><span class="highlight">{{ Math.round(data.no_response * 100) }}%</span> van jullie onvervulde shifts ontving wel een aanmelding, maar bleef onvervuld omdat jullie niet reageerden.</p>
        </div>
        <div class="section__right section__right--subgrid">
          <img class="side-image" src="./assets/img/img-side-03.jpg" alt="Young woman handing receipt to customer.">
        </div>
      </Section>
      <OutroSection
        title="Wist je dat..."
        :most_active_ff="data.most_active_ff"
        :ff_applied="data.ff_applied"
        :ff_worked="data.ff_worked"
        :feedback_to="data.feedback_to"
        :first_shift="data.first_shift_string"
      />
      <Footer
        :account_manager="data.account_manager"
        :phone="data.phone"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vSelect from 'vue-select';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollDownIndicator from './components/ScrollDownIndicator';
import ScrollToTopIndicator from './components/ScrollToTopIndicator';
import Intro from './components/Intro';
import IntroNotFound from './components/IntroNotFound';
import IntroSection from './components/IntroSection';
import OutroSection from './components/OutroSection';
import Section from './components/Section';
import { Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay } from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass);

import 'swiper/swiper-bundle.min.css';

Vue.component('v-select', vSelect);

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ScrollDownIndicator,
    ScrollToTopIndicator,
    Intro,
    IntroNotFound,
    Section,
    IntroSection,
    OutroSection,
    Swiper,
    SwiperSlide
  },
  created() {
    window.onpopstate = () => {
      this.getPageParams();
      this.fetchData(this.user_id, this.unique_id, this.dataset);
    }

    this.getPageParams();
    this.fetchData(this.user_id, this.unique_id, this.dataset);
  },
  data() {
    return {
      response: null,
      currentSlide: 1,
      data: null,
      dataset: { year: '', quarter: '' },
      datasetRequested: { year: '', quarter: '' },
      swiperOptions: {
        spaceBetween: 128,
        speed: 800,
        loop: true,
        threshold: 10,
        pagination: {
          el: '.swiper-pagination-custom',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
        }
      }
    }
  },
  methods: {
    trackEvent(event, eventName, eventLabel) {
      event.preventDefault();
      window.gtag('event', eventName || 'unnamed-event', {
        'event_label': eventLabel ? eventLabel : event.target && event.target.href ? event.target.href : '',
      });

      setTimeout(() => {
        document.location.href = event.target && event.target.href ? event.target.href : '/';
      }, 20);
    },
    changeSlideIndex() {
      return this.currentSlide = this.$refs.slidesSwiper?.$swiper.realIndex + 1;
    },
    getPageParams() {
      const pathnameSplit = location.pathname.split('/');

      this.dataset = {
        year: pathnameSplit[1],
        quarter: pathnameSplit[2],
      };

      this.datasetRequested = this.dataset;

      this.user_id = pathnameSplit[3];
      this.unique_id = pathnameSplit[4];
    },
    fetchData(user_id, unique_id, dataset) {
      const queryString = new URLSearchParams({
        user_id,
        id: unique_id || '',
        dataset: `${ dataset.year }_q${ dataset.quarter }`,
      }).toString();

      fetch(`${process.env.VUE_APP_DATA_ENDPOINT}?${queryString}`)
      .then(response => response.json())
      .then(result => {
        this.response = result;

        if (this.response.status === -1) {
          return;
        }

        const data = result.data;

        let months_activity = [
          {
            name: 'januari',
            published: data.pub_01,
            worked: data.work_01,
            coverage: data.cov_01
          },
          {
            name: 'februari',
            published: data.pub_02,
            worked: data.work_02,
            coverage: data.cov_02
          },
          {
            name: 'maart',
            published: data.pub_03,
            worked: data.work_03,
            coverage: data.cov_03
          },
          {
            name: 'april',
            published: data.pub_04,
            worked: data.work_04,
            coverage: data.cov_04
          },
          {
            name: 'mei',
            published: data.pub_05,
            worked: data.work_05,
            coverage: data.cov_05
          },
          {
            name: 'juni',
            published: data.pub_06,
            worked: data.work_06,
            coverage: data.cov_06
          },
          {
            name: 'juli',
            published: data.pub_07,
            worked: data.work_07,
            coverage: data.cov_07
          },
          {
            name: 'augustus',
            published: data.pub_08,
            worked: data.work_08,
            coverage: data.cov_08
          },
          {
            name: 'september',
            published: data.pub_09,
            worked: data.work_09,
            coverage: data.cov_09
          },
          {
            name: 'oktober',
            published: data.pub_10,
            worked: data.work_10,
            coverage: data.cov_10
          },
          {
            name: 'november',
            published: data.pub_11,
            worked: data.work_11,
            coverage: data.cov_11
          },
          {
            name: 'december',
            published: data.pub_12,
            worked: data.work_12,
            coverage: data.cov_12
          },
        ];

        months_activity.sort(compareMonths);

        data.months_active = months_activity.filter(month => month.worked > 0);

        data.top_months = data.months_active.slice(0, 3);

        data.most_active_month = data.top_months[0];

        data.first_shift_string = new Date(data.first_shift.replace(' 00:00:00', 'T00:00:00'));

        const dateOptions = {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        };

        data.first_shift_string = data.first_shift_string.toLocaleDateString('nl-NL', dateOptions);

        this.data = data;

        // The current dataset doesn't exist, replace it with the latest available
        if (result.latestDataSet) {
          const latestDataSetSplit = result.latestDataSet.split('_');

          this.dataset = {
            year: latestDataSetSplit[0],
            quarter: latestDataSetSplit[1].substring(1),
          };
        }

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 50);

        document.title = `${ window.GA_APP_NAME } | ${ data.client_name }`;

        window.gtag('send', 'pageview');
      })
      .catch(error => {
        console.log(error);

        this.response = {
          status: -1
        };

        // document.location.href = 'https://temper.works/';
      });
    }
  }
}

const compareMonths = (a, b) => {
  if (a.worked < b.worked) {
    return 1;
  } if (a.worked > b.worked) {
    return -1;
  }

  return 0;
};
</script>

<style lang="scss">
@import '@/scss/index.scss';
@import 'vue-select/src/scss/vue-select.scss';
</style>
